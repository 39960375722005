/**
 * English fonts
 * TITLE: Noto Sans
 * BODY: Arsenal
 */
/**/

/* Noto Sans */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* Arsenal */
@import url('https://fonts.googleapis.com/css2?family=Arsenal:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/**
 * Korean fonts
 * TITLE: Noto Sans KR
 * BODY: NanumSquare
 */
/**/

/* Noto Sans KR */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");

/* NanumSquare */
@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  src: url("./korean/nanumsquare/NanumSquareR.eot"),
  url("./korean/nanumsquare/NanumSquareR.eot?#iefix")
  format("embedded-opentype"),
  url("./korean/nanumsquare/NanumSquareR.woff2") format("woff2"),
  url("./korean/nanumsquare/NanumSquareR.woff") format("woff"),
  url("./korean/nanumsquare/NanumSquareR.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  src: url("./korean/nanumsquare/NanumSquareB.eot"),
  url("./korean/nanumsquare/NanumSquareB.eot?#iefix")
  format("embedded-opentype"),
  url("./korean/nanumsquare/NanumSquareB.woff2") format("woff2"),
  url("./korean/nanumsquare/NanumSquareB.woff") format("woff"),
  url("./korean/nanumsquare/NanumSquareB.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 800;
  src: url("./korean/nanumsquare/NanumSquareEB.eot"),
  url("./korean/nanumsquare/NanumSquareEB.eot?#iefix")
  format("embedded-opentype"),
  url("./korean/nanumsquare/NanumSquareEB.woff2") format("woff2"),
  url("./korean/nanumsquare/NanumSquareEB.woff") format("woff"),
  url("./korean/nanumsquare/NanumSquareEB.ttf") format("truetype");
}
@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  src: url("./korean/nanumsquare/NanumSquareL.eot"),
  url("./korean/nanumsquare/NanumSquareL.eot?#iefix")
  format("embedded-opentype"),
  url("./korean/nanumsquare/NanumSquareL.woff2") format("woff2"),
  url("./korean/nanumsquare/NanumSquareL.woff") format("woff"),
  url("./korean/nanumsquare/NanumSquareL.ttf") format("truetype");
}

/**
 * Japanese fonts
 * TITLE: Noto Sans JP
 * BODY: Noto Sans JP
 */
/**/

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;300;400;500;600;700;800;900&display=swap");

/**
 * Chinese fonts
 * TITLE: Noto Sans SC
 * BODY: Noto Sans SC
 */
/**/

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap");
